import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import 'tippy.js/animations/scale.css';
import './styles.css';

export const createTooltip = (target) =>  tippy(target, {
    theme: 'dark',
    animation: 'scale',
    duration: 250,
    arrow: true,
    delay: [0, 50],
    maxWidth: 240,
});
