import './styles.css';

export const initCookieBottomDrawer = () => {
    const storageData = localStorage.getItem('cookie-accept')
    const isCookieAccept = JSON.parse(storageData)

    if (isCookieAccept) return null

    const nodeContainer = document.createElement('div');
    nodeContainer.innerHTML = `<div class="bottom-drawer">
        <div class="bottom-drawer--container">
           <div class="bottom-drawer--container-text">Используя этот сайт, Вы даете согласие на обработку <a href="/docs/Cookie_policy.pdf" target="_blank">cookie-файлов</a> в целях корректного функционирования сайта и улучшения пользовательского опыта.</div>
           <button type="button" class="notification__action-btn action-btn action-btn--alert action-btn--outlined" style="align-self: center;margin: 0; max-width: 122px">Согласен</button>
        </div>
    </div>`;
    const node = nodeContainer.firstElementChild;
    node
        .querySelector('.notification__action-btn')
        .addEventListener(
            'click',
            (event) => {
                localStorage.setItem('cookie-accept', 'true')
                node.remove();
            }, {once: true}
        );

    document.body.appendChild(nodeContainer)

    return null;
};
