import {isProd} from "@/shared/isProd";

let VKAnalyticsStatus = null;
const VKAnalyticsCallbacks = [];

export const useVKAnalytics = (callback = null, onerror = null) => {
    if(isProd()) {
        if (VKAnalyticsStatus === 'error') {
            console.error('VK Analytics is not available');
            return;
        }

        if (VKAnalyticsStatus === 'loading') {
            console.error('VK Analytics is being loaded');
            if (callback) {
                VKAnalyticsCallbacks.push(callback);
            }

            return;
        }

        if (VKAnalyticsStatus === 'initialized' && callback) {
            callback();
            return;
        }

        if (!VKAnalyticsStatus) {
            VKAnalyticsStatus = 'loading';

            const script = document.createElement("script");
            script.type = 'text/javascript';
            script.src = 'https://vk.com/js/api/openapi.js?169';
            script.onload = function () {
                VKAnalyticsStatus = 'initialized';

                if (callback) {
                    callback();
                }

                VKAnalyticsCallbacks.forEach(callback => callback());
            };
            script.onabort = function () {
                VKAnalyticsStatus = 'error';
                if (onerror) {
                    onerror();
                }
            }
            script.onerror = function () {
                VKAnalyticsStatus = 'error';
                if (onerror) {
                    onerror();
                }
            }

            document.head.appendChild(script);
        }
    }
};
