export function createBurgerMenu() {
    const bar = document.querySelector('.bar');
    const menuBtn = document.querySelector('.hamburger-menu');
    const burgerNav = document.querySelector(".burger__nav");
    const pageBody = document.querySelector(".page__body");
    const htmlRoot = document.querySelector("html");
    const headerLogo = document.querySelector(".header-logo-red");

    menuBtn.addEventListener('click', function () {
        bar.classList.toggle('animate');
        this.classList.toggle("active");
        burgerNav.classList.toggle("open");
        pageBody.classList.toggle('body-lock');
        htmlRoot.classList.toggle("body-lock");
        headerLogo?.classList.toggle("home__header-red-open");
    });
}
