import Swiper from "swiper";
import 'swiper/css/bundle';

export function createFooterSlider(){
    new Swiper('.swiper-projects',{
        navigation: {
            nextEl: '.swiper-projects .gallery-button-next',
            prevEl: '.swiper-projects .gallery-button-prev'
        },
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 20,
        loop: true,
        breakpoints: {
            701: {
                slidesPerView: 3,
            },
        }
    })
}
