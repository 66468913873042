export function createHeaderDropdown() {
    let dropdownLink = document.querySelector('.header-dropdown-link');
    let dropdownWrapper = document.querySelector('.home__header-nav .dropdown-wrapper');
    dropdownLink.addEventListener('click', function () {
        dropdownWrapper.classList.toggle('active');
        dropdownLink.classList.toggle('active');
    });
    document.body.addEventListener('click', function (e) {
        if (e.target.classList.contains('dropdown-wrapper') == true) {
            dropdownWrapper.classList.add('active');
            dropdownLink.classList.add('active');
        } else if (e.target.classList.contains('header-dropdown-link') == false && e.target.classList.contains('dropdown-link') == false && e.target.classList.contains('dropdown-wrapper') == false) {
            dropdownWrapper.classList.remove('active');
            dropdownLink.classList.remove('active');
        }
    });

    if (document.getElementById('announce') && (new Date('2023-09-10 00:00:00')).getTime() > (new Date()).getTime()) {
        document.getElementById('announce').style.display = "flex";
    }
}
